import { css, keyframes } from '@emotion/core'
import React from 'react'
import activeDot from '../images/contact/active_dot.svg'
import inactiveDot from '../images/contact/inactive_dot.svg'

const activePulse = keyframes`
  0% {
    transform: scale(1);
    background: rgba(120, 144, 226, 0.5);
  }
  100% {
    transform: scale(2);
    background: rgba(120, 144, 226, 0);
  }
`

const inactivePulse = keyframes`
  0% {
    transform: scale(1);
    background: rgba(170, 178, 211, 0.5)
  }
  100% {
    transform: scale(1.75);
    background: rgba(170, 178, 211, 0)
  }
`

const Dot = ({
  src,
  text,
  size = 16,
  delay = 0,
  inactive,
  textPosition,
  ...props
}) => (
  <div
    css={css`
      position: absolute;
      width: ${size}px;
      height: ${size}px;
      border-radius: 50%;
      transform: translate(-50%, -50%);
    `}
    {...props}
  >
    <img
      src={inactive ? inactiveDot : activeDot}
      alt={text}
      css={css`
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
      `}
    />
    {[0, 1, 2].map(diff => (
      <div
        key={diff}
        css={css`
          position: absolute;
          border-radius: 50%;
          width: 100%;
          height: 100%;
          animation: ${inactive ? inactivePulse : activePulse} 3s infinite;
          animation-delay: ${diff + delay}s;
        `}
      />
    ))}
    <div
      css={theme => css`
        position: absolute;
        color: ${inactive ? '#aab2d3' : theme.colors.accent};
        text-transform: uppercase;
        font-weight: bold;
        font-size: ${inactive ? theme.fontSize.xxSmall : theme.fontSize.xSmall};
        ${textPosition}
      `}
    >
      {text}
    </div>
  </div>
)

export default Dot
