import { css } from '@emotion/core'
import React from 'react'
import { injectIntl } from 'gatsby-plugin-intl'
import worldMap from '../images/contact/world_map.svg'
import Dot from './Dot'

const Map = ({ intl, ...props }) => (
  <div
    {...props}
    css={css`
      width: 100%;
      position: relative;
    `}
  >
    <img
      src={worldMap}
      css={css`
        width: 100%;
      `}
      alt={intl.formatMessage({ id: 'world_map' })}
    />
    <Dot
      css={css`
        left: 52%;
        top: 38%;
        z-index: 1;
      `}
      text={intl.formatMessage({ id: 'budapest' })}
      textPosition={css`
        top: -16px;
        left: 18px;
      `}
    />
    {/* <Dot
      inactive
      css={css`
        left: 79%;
        top: 65%;
      `}
      size={12}
      delay={Math.round(Math.random() * 100) / 100}
      text={intl.formatMessage({ id: 'singapore' })}
      textPosition={css`
        top: 9px;
        right: 14px;
      `}
    /> */}
    {/* <Dot
      inactive
      css={css`
        left: 11%;
        top: 43%;
      `}
      size={12}
      delay={Math.round(Math.random() * 100) / 100}
      text={intl.formatMessage({ id: 'california' })}
      textPosition={css`
        top: -14px;
        left: 14px;
      `}
    /> */}
    {/* <Dot
      inactive
      css={css`
        left: 50%;
        top: 39%;
      `}
      size={12}
      delay={Math.round(Math.random() * 100) / 100}
      text={intl.formatMessage({ id: 'zurich' })}
      textPosition={css`
        top: 9px;
        right: 14px;
      `}
    /> */}
  </div>
)

export default injectIntl(Map)
