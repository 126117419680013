import { css } from '@emotion/core'
import { injectIntl } from 'gatsby-plugin-intl'
import React from 'react'
import Fade from 'react-reveal/Fade'
import { EmailLink, PhoneLink } from '../components/common/index'
import Layout from '../components/Layout'
import Map from '../components/Map'
import SEO from '../components/Seo'
import breakpoints from '../utils/breakpoints'

const ContactPage = ({ intl }) => {
  return (
    <Layout lightFooter>
      <SEO
        title={intl.formatMessage({ id: 'contact' })}
        lang={intl.locale}
        keywords={intl.formatMessage({ id: 'keywords' })}
        description={intl.formatMessage({ id: 'contact_description' })}
      />
      <div>
        <div
          css={theme => css`
            position: relative;
            font-size: ${theme.fontSize.small};
            width: 100%;
          `}
        >
          <div
            css={theme => css`
              color: ${theme.colors.accent};
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100vw;
              max-height: 200px;
              font-size: ${theme.fontSize.xxLarge};
              background: ${theme.colors.white};
              z-index: 1;
              font-weight: 100;

              ${breakpoints.mobile} {
                font-size: ${theme.fontSize.xLarge};
              }
            `}
          >
            {intl.formatMessage({ id: 'contact_page_title' })}
          </div>
        </div>
        <div
          css={theme => css`
            background: ${theme.colors.fullWhite};
          `}
        >
          <Fade bottom distance="8px" duration={750} cascade>
            <div
              css={theme => css`
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                padding: 48px 16px 48px 16px;
                font-size: ${theme.fontSize.medium};

                ${breakpoints.mobile} {
                  padding: 32px 16px 32px 16px;
                }
              `}
            >
              <EmailLink
                css={theme => css`
                  margin: 4px 32px;
                  display: block;
                  color: ${theme.colors.accent};

                  ${breakpoints.mobile} {
                    margin: 12px;
                  }
                `}
                data="hello@silivey.com"
              />
              <PhoneLink
                css={theme => css`
                  color: ${theme.colors.accent};
                  white-space: nowrap;
                  margin: 4px 32px;
                  display: block;

                  ${breakpoints.mobile} {
                    margin: 12px;
                  }
                `}
                data="+36 (30) 903 2900"
              />
            </div>
            <Map
              css={css`
                width: 90%;
                margin: 0px auto;
                max-width: 900px;
                height: 53vw;
                max-height: 530px;
              `}
            />
          </Fade>
        </div>
      </div>
    </Layout>
  )
}

export default injectIntl(ContactPage)
